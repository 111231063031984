.container {
	width: 100%;
	height: 100%;
    background: rgb(35, 39, 65);
    // border: 1px solid red;
	// background: linear-gradient(to left, rgb(6, 34, 60), #0a1f42);
}

.content {
	width: 400px;
	height: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: rgba(0, 0, 0, 0.3);
	padding: 40px;
	padding-bottom: 0;
	border: 1px solid rgba(0, 0, 0, 0.7);
	border-radius: 5px;
	box-shadow: 0 0 5px #333;
}

.title {
	text-align: center;
	color: #fff;
	font-size: 26px;
	margin-bottom: 25px;
}
// .ant-form-item-label>label{
// 	color: white !important;
// }